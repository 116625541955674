<template>
    <div class="contain">
        
        <Header :config="config"  />

        <div class="main-container">
            <sidebar  />
            
            <div class="main-content">
                <div class="main-page" ref="pageLists"  id="osComponentRef">
                    <div class="channel-container">
                        <span @click="handleTab(0)"  :class="activeChannel" class="channel ">推荐</span>

                        <span @click="handleTab(1)"  :class="activeChannel1" class="channel">最新</span>
                    </div>

                    <div class="page-list"  v-masonry transition-duration="0s" item-selector=".note-item">
                        <div class="note-item"  v-masonry-tile  v-for="(column,index) in contentArr" :key="index" >
                            <article-list :column=column />
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
</template>


<script>

import Vue from 'vue'
import {VueMasonryPlugin} from 'vue-masonry';
Vue.use(VueMasonryPlugin)

import VueQr from 'vue-qr';
import Header from '@/components/Header/header.vue';
import sidebar from '@/components/sidebar/sidebar.vue';
import articleList from '@/components/article-list/article-list.vue';

import { apiArticleLists,apiHandleArticleCollect } from '@/api/app'

import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'index',
    components: {
        articleList,
        sidebar,
        Header,
        VueQr
    },

    data() {
        return {
            setResize: 'both',
            options:{},
            searchForm:{
                search:''
            },
            contentArr: [
               
            ],
            columns: [],
            arrIndex:[],
            loading:false,

            type:'',
            page_no:1,
            page_size:12,
            is_page:true,
            activeChannel:'active',
            activeChannel1:''

        }
    },

    mounted() {
        // const myDiv = this.$refs.pageLists
        window.addEventListener('scroll', this.handleScroll)



    },
    beforeDestroy() {
        // const myDiv = this.$refs.pageLists
        window.removeEventListener('scroll', this.handleScroll)
    },
    computed: {
        ...mapGetters(['config','userInfo','isLogin']),
    },

    provide() {
        return {
            
			reChatServe: this.reChatServe,
        }
    },

    async created() {
		
       console.log( this.isLogin)

        this.getArcileLists()
        
    },

    methods: {
        
        handleTab(type){
            this.activeChannel = ''
            this.activeChannel1 = ''
            if( type == 0){
                this.activeChannel = 'active'
                this.type = ''
            } else {
                this.activeChannel1 = 'active'
                this.type = 'is_news'
            }

            this.page_no =  1
            this.is_page = true
            this.getArcileLists()
        },
        handleScroll($event){
            
            const scrollHeight = document.documentElement.scrollHeight // 页面总高度
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 滚动条距离顶部的高度
            const clientHeight = document.documentElement.clientHeight // 视口高度

            if (scrollTop + clientHeight >= scrollHeight  && this.is_page) {
                // do something when the page is scrolled to the bottom
                this.page_no = this.page_no + 1
                this.getArcileLists()
            }

        },
        getArcileLists(){

            const {page_no ,page_size,type} = this
            apiArticleLists({
                type:type,
                page_no:page_no,
                page_size:page_size,
            }).then(data => {
                // console.log(data)
                if(data.lists.length <=0 ){
                    this.is_page = false
                }
               // 如果是第一页需手动置空列表
                if (page_no == 1) this.contentArr = []
                // 重置列表数据
                this.contentArr = [...this.contentArr, ...data.lists]
            }).catch(err => {
                
            })
        },
    },
}
</script>


<style lang="scss" scoped>

    html,body,#app{
        background:#ffffff;
    }
    .header-container{
        background:#ffffff;
    }
    .contain{
        // width:1700px;
        width: 100%;
        margin: 0 auto;
    }

    
    .main-container{
        display: flex;
    }
    
   

    .overlay-scrollbars{
        height:399px;
    }
    .main-content{
        width: 100%;
        padding-left: calc(-6px + 266.66667px);
        .main-page{
            
            padding:85px 0 20px 35px;
        }
        .channel-container{
            height: 70px;
            display: flex;
            align-items: center;
        }
        .channel{
            cursor: pointer;
            border-radius: 40px;
            font-size: 16px;
            color: #333;
            background: #ffffff;
            border: none;
            padding: 8px 15px;
            font-weight: 500;
        }
        .channel.active{
            background: #f8f8f8;
            font-weight:600;
            color: #333;
        }
        .page-list{
            width: 100%;
            transition: width .5s;
            margin: 0 auto;
            justify-content: space-around;
            transition: all 0.5s ease-in-out;


            .note-item {
               
                width: 245px;
                //  display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.5s ease-in-out;
                margin:0 20px 0 10px;
            }
        }
    }

</style>
